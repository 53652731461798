import React from 'react'
import wordpressStyles from './wordpress-overview.module.scss'
import { StaticImage } from "gatsby-plugin-image"

const WordpressOverview = (props) => {

    return (
        <section className={wordpressStyles.wordpress}>
            
            <div className={"container " + wordpressStyles.wordpress__intro}>

                <div className={wordpressStyles.wordpress__intro__title}>
                    <h1 className="hintro">Cloud <span>WordPress</span></h1>
                </div>

                <div className={wordpressStyles.wordpress__intro__text}>
                    <p>All the <span>performance</span> and <span>features</span> to get the most out of your WordPress site, featuring staging environments, advanced security, and <span>incredibly fast</span> server-side caching, all built on our cloud platform.</p>
                </div>

            </div>

            <div className={wordpressStyles.wordpress__wire}>

                <div className={wordpressStyles.wordpress__wire__top}></div>

                <div className={wordpressStyles.wordpress__wire__bottom}></div>

                <div className={wordpressStyles.wordpress__overflow}>

                    <div className={"container " + wordpressStyles.wordpress__wide}>

                        <div className={wordpressStyles.wordpress__bar}></div>

                        <div className={wordpressStyles.wordpress__install}>
                        
                            <div className={wordpressStyles.wordpress__picture}>

                                <div className={wordpressStyles.wordpress__picture__inner}>
                                    <StaticImage
                                        src="../images/features/wordpress-toolkit.jpeg"
                                        alt="WordPress Toolkit"
                                        width={768}
                                    />
                                </div>

                                <div className={wordpressStyles.wordpress__picture__text}>

                                    <h2 className="hintro"><span>WordPress</span> Toolkit</h2>

                                    <p>Stage, Clone, Sync, Update and Migrate your website with one click using our easy-to-use management interface made just for WordPress.</p>

                                </div>
                            </div>
                        
                        </div>

                        <div className={wordpressStyles.wordpress__barleft}></div>

                    </div>

                </div>
            
            </div>

            <div className={wordpressStyles.wordpress__information}>
                
                <div className={"container " + wordpressStyles.wordpress__information__text}>

                    <ul>
                        <li>
                            <i className="icon icon-transfer"></i>
                            <div>
                                <h3><span>Development</span></h3>
                                <p>One-click staging and cloning functionality, mass automatic and smart updates for plugins and themes</p>
                            </div>
                        </li>
                        <li>
                            <i className="icon icon-lock"></i>
                            <div>
                                <h3><span>Secure</span></h3>
                                <p>Advanced security features, automatic security hardening, and SSL certificates as standard</p>
                            </div>
                        </li>
                        <li>
                            <i className="icon icon-wordpress"></i>
                            <div>
                                <h3><span>PageSpeed X-Ray</span></h3>
                                <p>Performance tools to identify website slowdowns, plugin issues or make improvements to create the quickest websites</p>
                            </div>
                        </li>
                        <li>
                            <i className="icon icon-check"></i>
                            <div>
                                <h3><span>Reliable</span></h3>
                                <p>Always online with a 99.95% Uptime SLA as Standard and 100% for High Availability</p>
                            </div>
                        </li>
                    </ul>

                </div>

                <div className={wordpressStyles.wordpress__information__bottom}></div>
            
            </div>

            <div className={wordpressStyles.wordpress__speed}>

                <div className={wordpressStyles.wordpress__speed__top}></div>

                <div className={wordpressStyles.wordpress__speed__text}>
                    <div className={"container " + wordpressStyles.wordpress__speed__text__container}>

                        <div className={wordpressStyles.wordpress__speed__text__title}>
                            <h2>Let's talk <span>Speed</span></h2>
                        </div>

                        <div className={wordpressStyles.wordpress__speed__text__text}>
                            <p>Superior performance using <span>LiteSpeed</span> Web Server* and server-level caching with <span>LSCache</span>, combing this with faster-than <span>SSD</span> hard drives** produces some impressive results.</p>

                            <p>*Host3 <i>WordPress</i> and <i>Cloud Hosting</i> packages with LiteSpeed and LSCache vs NGINX with FastCGI Cache, Apache and W3 Total Cache. **Sysbench read and write storage performance vs DigitalOcean and AWS EC2</p>
                        </div>

                        <div className={wordpressStyles.wordpress__speed__text__results}>
                            <div className={wordpressStyles.wordpress__speed__text__results__table}>
                                <div className={wordpressStyles.wordpress__speed__text__results__table__item}>
                                    <p><span>LiteSpeed</span><i>28x</i></p>
                                    <p><span>NGINX</span><i>5x</i></p>
                                    <p><span>Apache</span><i>1x</i></p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>

            <div className={wordpressStyles.wordpress__ha}>

                <div className={wordpressStyles.wordpress__ha__inner}>
                    <div className={wordpressStyles.wordpress__ha__top}></div>

                    <div className={wordpressStyles.wordpress__ha__text}>
                    
                        <h2 className="hintro"><span>High Availability</span></h2>

                        <p>Take your WordPress website to the next level with our multi-cloud high availability package. Built using our <span>12 years</span> of experience to meet the needs of even the most demanding WordPress websites backed by a <span>100% uptime</span> SLA.</p>

                    </div>

                    <div className={wordpressStyles.wordpress__ha__bottom}></div>

                </div>

            </div>

            <div className={wordpressStyles.wordpress__gap}></div>

            <div className={wordpressStyles.wordpress__plans}>

                <div className={wordpressStyles.wordpress__plans__inner}>

                    <div className={wordpressStyles.wordpress__plans__items}>
                    
                        <div className={wordpressStyles.wordpress__plans__item}>

                            <div className={wordpressStyles.wordpress__plans__item__inner}>
                                <div className={wordpressStyles.wordpress__plans__item__title}>
                                    Standard
                                </div>

                                <div className={wordpressStyles.wordpress__plans__item__price}>
                                    &pound;17<i>.95</i>
                                    <span>per month</span>
                                </div>

                                <div className={wordpressStyles.wordpress__plans__bar}>
                                    <p>10 GB Storage</p>
                                    <i><span></span></i>
                                </div>

                                <div className={`${wordpressStyles.wordpress__plans__bar} ${wordpressStyles.wordpress__plans__bar}`}>
                                    <p>100 GB Bandwidth</p>
                                    <i><span></span></i>
                                </div>

                                <a href="https://account.host3.co.uk/cart.php?a=add&pid=1" className="button button--large button__blue">Select</a>
                            </div>

                        </div>
                    
                        <div className={wordpressStyles.wordpress__plans__item}>

                            <div className={wordpressStyles.wordpress__plans__item__inner}>
                                <div className={wordpressStyles.wordpress__plans__item__title}>
                                    High Availability<span>100% Uptime SLA</span>
                                </div>

                                <div className={wordpressStyles.wordpress__plans__item__price}>
                                    &pound;36<i>.95</i>
                                    <span>per month</span>
                                </div>

                                <div className={wordpressStyles.wordpress__plans__bar}>
                                    <p>15 GB Storage</p>
                                    <i><span></span></i>
                                </div>

                                <div className={`${wordpressStyles.wordpress__plans__bar} ${wordpressStyles.wordpress__plans__bar__ha}`}>
                                    <p>250 GB Bandwidth</p>
                                    <i><span></span></i>
                                </div>

                                <a href="https://account.host3.co.uk/cart.php?a=add&pid=4" className="button button--large button__blue">Select</a>
                            </div>

                        </div>

                    </div>
            
                    <div className={wordpressStyles.wordpress__plans__top}></div>

                    <div className={wordpressStyles.wordpress__plans__side}></div>

                    <div className={wordpressStyles.wordpress__plans__bottom}></div>

                </div>
            
            </div>

            <div className={wordpressStyles.wordpress__wave}></div>

        </section>
    )
}

export default WordpressOverview