import React from 'react'

import Store from '../components/store'
import Head from '../components/head'
import Navigation from '../components/navigation'
import Header from '../components/header'
import WordpressOverview from '../components/wordpress-overview'
import Logos from '../components/logos'
import Talk from '../components/lets-talk'
import Footer from '../components/footer'
import Cookies from '../components/cookies'

const Plans = () => {
    return (
        <Store>
            <Head title="WordPress Cloud Hosting" />
            <Navigation />
            <Header disable />
            <WordpressOverview/>
            <Logos/>
            <Talk/>
            <Footer/>
            <Cookies />
        </Store>
    )
}

export default Plans
